import Perfume from 'perfume.js';
import Bowser from 'bowser';

function logData(data) {
    const url = 'https://index-log.getresponse.com/index/marketing_performance';
    const request = new XMLHttpRequest();

    request.open('POST', url, true);

    request.setRequestHeader('Method', `POST ${url} HTTP/1.1`);
    request.setRequestHeader('Content-type', 'application/json');

    request.withCredentials = true;
    request.timeout = 3600;

    request.send(JSON.stringify(data));
}

function screenInfo() {
    const ret = {};
    ret.width = window.innerWidth;
    ret.height = window.innerHeight;

    if (typeof window.screen !== 'undefined') {
        if (typeof window.screen.availWidth !== 'undefined') {
            ret.availWidth = window.screen.availWidth;
        }
        if (typeof window.screen.availHeight !== 'undefined') {
            ret.availHeight = window.screen.availHeight;
        }
    }
    if (typeof window.devicePixelRatio !== 'undefined') {
        ret.devicePixelRatio = Math.round(window.devicePixelRatio * 100) / 100;
    }

    return ret;
}

const clientInfo = Bowser.parse(window.navigator.userAgent);
const additionalInfo = {
    event_type: 'PERFORMANCE',
    responsible: 'dms',
    page: document.getElementById('global_data') ? JSON.parse(document.getElementById('global_data').innerText).parent_shortname : null,
    location: {
        href: `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`,
        fulladdress: window.location.href,
        search: window.location.search,
        hostname: window.location.hostname,
        pathname: window.location.pathname,
    },
    screen: screenInfo(),
};

// eslint-disable-next-line no-new
new Perfume({
    analyticsTracker: (options) => {
        const { metricName, data } = options;
        switch (metricName) {
        case 'navigationTiming':
            if (data && data.timeToFirstByte) {
                const filteredData = Object.keys(data)
                    .filter((key) => data[key] >= 0).reduce((obj, key) => {
                        const newObj = { ...obj };
                        newObj[key] = data[key];
                        return newObj;
                    }, {});
                logData({
                    metricData: filteredData, metricName: 'navigationTiming', ...clientInfo, ...additionalInfo,
                });
            }
            break;
        case 'networkInformation':
            if (data && data.effectiveType) {
                logData({
                    metricData: data, metricName: 'networkInformation', ...clientInfo, ...additionalInfo,
                });
            }
            break;
        case 'fp':
            logData({
                metricData: { duration: data }, metricName: 'firstPaint', ...clientInfo, ...additionalInfo,
            });
            break;
        case 'fcp':
            logData({
                metricData: { duration: data }, metricName: 'firstContentfulPaint', ...clientInfo, ...additionalInfo,
            });
            break;
        default:
            break;
        }
    },
});
